//
// team.scss
//
.team-box {
    .team-img {
        position: relative;
        .team-content {
            position: absolute;
            bottom: -40px;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 0.5s ease;
            ul {
                li {
                    a{
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        color: $primary;
                        background-color: $white;
                        border-radius: 40px;
                        transition: all 0.5s ease;
                        &:hover {
                            color: $white;
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
    &:hover {
        .team-img {
            .team-content {
                bottom: 10px;
                opacity: 1;
            }
        }
    }
}