//
// footer.scss
//
.footer-subscribe {
  border-bottom: 1px solid rgba($white, 0.1);
}

.bg-footer {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1021%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(25%2c 43%2c 51%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1328.55 0L1440 5.32z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1328.55 0L1440 5.32L1440 39.89L1098.28 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1098.28 0L1440 39.89L1440 258.76L1063.27 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M1063.27 0L1440 258.76L1440 377.65L869.17 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L306.29 560L0 398.12z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 398.12L306.29 560L412.79 560L0 242.89000000000001z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 242.89L412.79 560L680.6500000000001 560L0 127.32z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 127.32L680.6500000000001 560L1193.8600000000001 560L0 86.03z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1021'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-position: center;
  background-size: cover;
  padding: 35px 0;
  .footer-link {
    a {
      color: rgba($white, 0.5);
      line-height: 38px;
      transition: all 0.5s;
      &:hover {
        color: $white;
      }
    }
  }
  .footer-subcribe {
    input {
      padding: 12px 20px;
      width: 100%;
      font-size: 14px;
      border: none;
      outline: none;
      padding-right: 75px;
      padding-left: 15px;
      border-radius: 5px;
      font-weight: 500;
    }
    button {
      position: absolute;
      top: 0px;
      right: 0px;
      outline: none;
      border-radius: 0px 5px 5px 0px;
      font-size: 14px;
      padding: 11px 20px;
    }
    form {
      position: relative;
      max-width: 400px;
    }
  }
}

.footer-social {
  a {
    color: rgba($white, 0.5);
    font-size: 18px;
    transition: all 0.5s;
    display: inline-block;
    &:hover {
      color: $white;
    }
  }
}

.footer-alt {
  background-color: #15242b;
}
