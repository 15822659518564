//
// contact.scss
//

.custom-form {
  .form-label {
    font-weight: 600;
  }
  .form-control {
    font-size: 15px;
    padding:12px 16px;
    border-color: rgba($gray-600, 0.40);
    &:focus {
      outline: none;
      border-color: rgba($gray-600, 0.75);
      box-shadow: none;
    }
    &::placeholder {
      color: $gray-500;
    }
  }
}

.error-msg{
  padding: 13px 20px;
  color: $danger;
  border-color: $danger;
  background-color: $white;
}

.contact-info{
  .icon {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -7px;
      width: 45px;
      height: 45px;
      background-color: rgba($primary, 0.25);
      margin: 0px auto;
      border-radius: 5px;
      transform: rotate(
        45deg
        );
    }
  }
}