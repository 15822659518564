//
// cta.scss
//

.bg-cta {
  position: relative;
  padding: 105px 0;
  //background-color: #0f3b76;
  background-image: url('../images/startjourney.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  color: white;
}
